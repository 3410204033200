import { TimePickerProps } from 'antd';
import classnames from 'classnames';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import { StyledTimePicker } from './TimePicker.styles';
import { TimePickerGlobalStyles } from './TimePicker.global.styles';
import { DateTime } from 'lib/utils/date-handling/DateTime';

export const TimePicker = (props: TimePickerProps): JSX.Element => {
  const { className, allowClear = false, ref, ...rest } = props;
  const timePickerClassName = classnames('time-picker', className);
  const use12Hours = DateTime.isUsing12HourClock(navigator.language);
  const formatDate = use12Hours ? 'h:mm a' : 'HH:mm';

  return (
    <>
      <StyledTimePicker
        className={timePickerClassName}
        popupClassName={'time-picker__popup'}
        format={formatDate}
        allowClear={allowClear}
        use12Hours={use12Hours}
        suffixIcon={<SvgIcon name="dataAgeOneToThreeDays" className="time-picker__icon" />}
        {...rest}
      />
      <TimePickerGlobalStyles />
    </>
  );
};
