/* eslint-disable max-len */
import { AutoComplete } from 'antd';
import styled from 'styled-components';

export const StyledAutoComplete = styled(AutoComplete)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 2px solid ${(props): string => props.theme.colors.darkGrey};
  }

  .ant-input-affix-wrapper {
    border: none;
    box-shadow: none !important;
    border-right-width: 1px !important;
    border-color: ${(props): string => props.theme.colors.darkGrey};

    :hover {
      border-color: ${(props): string => props.theme.colors.darkGrey};
      border-right-width: 1px !important;
    }
  }

  &.ant-select.ant-select-single,
  &.ant-select.ant-select-multiple {
    .ant-select-selector {
      border-radius: 0;
      border: 2px solid ${(props): string => props.theme.colors.darkGrey};

      :hover {
        background-color: ${(props): string => props.theme.colors.brightGrey};
      }

      :focus {
        border-color: ${(props): string => props.theme.colors.focusBorder};
      }

      :active {
        color: ${(props): string => props.theme.colors.darkGrey};
      }
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
    &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-right-width: 1px !important;
      box-shadow: none;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${(props): string => props.theme.colors.darkGrey};
    }

    &.ant-select-focused {
      .ant-select-selector {
        border-color: ${(props): string => props.theme.colors.focusBorder};
      }
    }

    &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      )
      .ant-select-selector {
      :hover {
        background-color: ${(props): string => props.theme.colors.brightGrey};
      }
    }
  }
`;
